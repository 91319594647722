import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";

const BlogPost = (props) => {
  console.log(props);
  const post = props.data.mdx;
  return (
    <Layout>
      {post && (
        <article class="border-b-2 border-gray-50 pb-8 prose">
          <h1 class="text-5xl">{post.frontmatter.title}</h1>
          <MDXRenderer>{post.body}</MDXRenderer>
          {/* add author name(s) & date */}
          {/* TODO: add other posts; tags etc. */}
        </article>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        date
        slug
      }
      fields {
        slug
      }
      id
      excerpt
      body
    }
  }
`;

export default BlogPost;
